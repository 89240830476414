import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const ArticlePreview = ({ posts }) => {
  if (!posts) return null
  if (!Array.isArray(posts)) return null

  return (
    <div className="bg-white py-4 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-12 lg:pb-24">
      <div className="relative max-w-5xl mx-auto">
        <h2 className="text-2xl text-gray-900 sm:text-3xl text-left mb-10">News</h2>
        <div className="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-3">
          {posts.map((post) => {
            return (
              <Link to={`/blog/${post.slug}`} key={post.slug}>
                <Post key={post.id}>
                  <GatsbyImage image={post.heroImage.gatsbyImageData} alt={post.title} />
                  <span>{post.publishDate}</span>
                  <span>{post.title}</span>
                  <span>{post.description}</span>
                </Post>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const Post = ({ children }) => (
  <div className="bg-white overflow-hidden ">
    <div className="pb-3">
      <div>
        {children[0]}
      </div>
      {/* post.title */}
      <div className="mt-1 text-2xl font-medium text-primary px-4 pt-5">
        {children[2]}
      </div>
      {/* post.description */}
      <div className="mt-2 text-l text-gray-400 truncate px-4">
        {children[3]}
      </div>
    </div>
  </div>
);

export default ArticlePreview
